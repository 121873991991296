<template>
  <NavbarHome3 />
  <Breadcrumb :mainTitle="currentBlog.title" :subTitle="currentBlog.title" />
  <!-- Blog Area  -->

  <div id="blog-page" class="blog-section section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="single-blog-wrap">
            <img v-if="currentBlog?.imageUrl" :src="currentBlog.imageUrl" :alt="currentBlog.title" />
            <div class="blog-meta">
              <span><i class="las la-user"></i>Admin</span>
              <span><i class="las la-calendar"></i>{{ currentBlog.dateAdded?.split('T')[0] }}</span>
            </div>
            <h3>{{ currentBlog.title }}</h3>
            <div v-html="currentBlog.content"></div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="recent-post">
            <h5>Zadnje novosti</h5>
            <router-link v-for="recentBlog in events" :key="recentBlog.blogId" :to="{ name: 'event', params: { id: recentBlog.blogId } }">
              <img v-if="recentBlog.imageUrl" :src="recentBlog.imageUrl" alt="" />
              <img v-else src="/assets/images/evenet-default.jpeg" alt="" style="" />
              <div class="single-recent-post">
                <h6>{{ recentBlog.title}}</h6>
                <p class="blog-date">
                  <i class="las la-calendar"></i>{{ recentBlog.dateAdded?.split('T')[0] || '' }}
                </p>
              </div>
            </router-link>
          </div>
          <div class="helpline-section">
            <div class="overlay-2"></div>
            <div class="helpline-content text-center">
              <h4>
                Pristup <br />
                udruženju
              </h4>
              <p>Želite postati član udruženja</p>
              <router-link class="main-btn"
                :to="{
                  name: 'clanstvo',
                }"
                >Podnesi prijavu</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";

import axios from "axios";

export default {
  name: "SingleBlog",
  components: { NavbarHome3, Breadcrumb, Footer },

  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },

  computed: {
    currentBlog() {
      return this.$store.getters.currentEvent || {};
    },

    currentBlogId() {
      return this.$route.params.id;
    },

    events() {
      return this.$store.getters.events.filter((event) => event.blogId !== this.currentBlog.blogId).slice(0, 3) || [];
    },
  },

  watch: {
    currentBlogId(newVal) {
      this.$store.dispatch("fetchEvent", newVal);
    },
  },
  
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "UPUFBiH",
  description: "UPUFBiH",
  ogDescription: "UPUFBiH",
  ogTitle: "UPUFBiH",
});
</script>
