import axios from 'axios';

const API_URL = 'https://api.upufbih.aksa.dev/api/';
// const API_URL = 'http://localhost:5000/api/';

export default {
    async fetchEvents({ commit }, tag) {
        const response = await axios.get(`${API_URL}Blogs/GetActiveBlogs?tag=${tag}`);
        commit('setEvents', response.data);
    },
    async fetchProjects({ commit }, tag) {
        const response = await axios.get(`${API_URL}Blogs/GetActiveBlogs?tag=${tag}`);
        commit('setProjects', response.data);
    },
    async fetchGalleryPhotos({ commit }) {
        const response = await axios.get(`${API_URL}Gallery/GetActivePhotos`);
        commit('setGalleryPhotos', response.data);
    },
    async fetchDocuments({ commit }, tag) {
        const response = await axios.get(`${API_URL}Blogs/GetActiveBlogs?tag=${tag}`);
        commit('setDocuments', response.data);
    },

    async fetchEvent({ commit }, id) {
        const response = await axios.get(`${API_URL}Blogs/GetBlogById?id=${id}`);
        commit('setCurrentEvent', response.data);
    }
};