<template>
    <NavbarHome3 />

  <Breadcrumb mainTitle="Novosti" subTitle="Novosti" />
  <!-- Blog Area  -->

  <div id="blog-page" class="blog-section section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="single-blog-item" v-for="blog in events" :key="blog.id">
            <div class="blog-bg">
              <img v-if="blog.imageUrl" :src="blog.imageUrl" :alt="blog.title" />
            </div>
            <div class="blog-content">
              <p class="blog-meta">
                <i class="las la-user-circle"></i>Admin |
                <i class="las la-calendar-check"></i>{{ blog.dateAdded.split('T')[0] }}
              </p>
              <router-link
                :to="{ name: 'event', params: { id: blog.blogId } }"
                >{{ blog.title }}</router-link
              >
              <div v-html="eventContent(blog.content)"></div>
              <router-link
                :to="{ name: 'event', params: { id: blog.blogId } }"
                class="read-more"
                >Pročitaj više</router-link
              >
              <div>
                <i class="las la-paperclip"></i>{{ blog.fileUrl ? '1' : '0' }}
              </div>
            </div>
          </div>

          <!-- <div class="pagination-block mb-15">
            <a class="page-numbers" href="/blog">1</a>
            <a class="page-numbers current" href="/blog">2</a>
            <a class="next page-numbers" href="/blog"
              ><i class="las la-arrow-right"></i
            ></a>
          </div> -->
        </div>
        <div class="col-lg-4">
          <!-- <div class="blog-search">
            <form action="/blog">
              <input type="search" placeholder="Search here" />
              <button type="submit"><i class="las la-search"></i></button>
            </form>
          </div> -->
          <div class="blog-category">
            <h5>Posljednji dodani dokumenti</h5>
            <a
              v-for="doc in documents"
              :key="doc.blogId"
              :href="doc.fileUrl"
              >{{ doc.title }}</a
            >
          </div>
          <div class="blog-category">
            <h5>Posljednji dodani projekti</h5>
            <a
              v-for="pro in projects"
              :key="pro.blogId"
              :href="pro.fileUrl"
              ><p v-html="pro.content"></p></a
            >
          </div>
          <div class="helpline-section">
            <div class="overlay-2"></div>
            <div class="helpline-content text-center">
              <h4>
                Pristup <br />
                udruženju
              </h4>
              <p>Želite postati član udruženja</p>
              <router-link class="main-btn"
                :to="{
                  name: 'clanstvo',
                }"
                >Podnesi prijavu</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Blog",
  components: { NavbarHome3, Breadcrumb, Footer },
  data() {
    return {
      blogs: [],
      archives: [],
      categories: [],
      tags: [],
    };
  },

  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },

  computed: {
    events() {
      return this.$store.getters.events;
    },

    projects() {
      return this.$store.getters.projects.slice(0, 3) || [];
    },

    documents() {
      return this.$store.getters.documents.slice(0, 3) || [];
    },
  },

  methods: {
    eventContent(content) {
      return content.substring(0, 100) + "...";
    },
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Novosti | UPUFBiH",
  description: "Novosti | UPUFBiH",
  ogDescription: "Novosti | UPUFBiH",
  ogTitle: "Novosti | UPUFBiH",
});
</script>
