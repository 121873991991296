<template>
  <!-- Footer Area -->

  <footer class="footer-area">
    <div class="container">
      <div class="footer-up">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="logo">
              <img src="/assets/img/logo-white.png" alt="pitron-logo" />
            </div>
            <div class="contact-info">
              <p><b>Adresa:</b> {{ homeData.address }}</p>
              <p><b>Telefon:</b> {{ homeData.phone }}</p>
              <p><b>Email:</b> {{ homeData.mail }}</p>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 com-sm-12">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <h6>Linkovi</h6>
                <ul>
                  <li>
                    <router-link :to="{ name: 'oudruzenju' }">O udruženju</router-link>
                    <router-link :to="{ name: 'documents' }"
                      >Dokumenti</router-link
                    >
                    <router-link :to="{ name: 'projects' }"
                      >Projekti</router-link
                    >
                    <router-link :to="{ name: 'events' }"
                      >Novosti</router-link
                    >
                    <router-link :to="{ name: 'gallery' }"
                      >Galerija slika</router-link
                    >
                    <router-link class="nav-link" :to="{ name: 'contact' }"
                      >Kontakt</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <h6>Propisi</h6>
                <ul>
                  <li>
                    <router-link :to="{ name: 'statut' }"
                      >Statut udruženja</router-link
                    >
                    <router-link :to="{ name: 'kodeks' }"
                      >Kodeks pravobranilačke etike</router-link
                    >
                    <router-link :to="{ name: 'pravila' }"
                      >Pravila o postupku pred Sudom časti</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="subscribe-form">
              <p>Udruženje pravobranilaca/pravobranitelja U Federaciji Bosne i Hercegovine predstavlja asocijaciju koja je formirana na slobodnoj osnovi s ciljem eﬁkasnijeg ostvarivanja profesionalnih aktivnosti usmjerenih na jačanje digniteta pravobranilačke funkcije, te promovisanja i zaštite kako zajedničkih tako i pojedinačnih prava i interesa svakog pravobranioca člana udruženja.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <!-- Footer Bottom Area  -->

  <div class="footer-bottom">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-lg-3 col-12">
          <div class="copyright-area">
            <p class="copyright-line">© 2024 UPUFBiH. Sva prava pridržana.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      homeData: [],
    };
  },

  async mounted() {
    let fetchedData = await axios.get("/assets/data/homeData.json");
    this.homeData = fetchedData.data.homeData;
  },
};
</script>
