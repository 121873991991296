<template>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
            <span v-if="formErrors.name" class="error-message">Ime i prezime je obavezno</span>
          <input type="text" placeholder="Imae i prezime" v-model="formData.name" :class="{ 'error': formErrors.name }" />
        </div>
        <div class="col-lg-6 col-md-6 col-12">
            <span v-if="formErrors.email" class="error-message">Email je obavezan</span>
          <input type="email" placeholder="Email" v-model="formData.email" :class="{ 'error': formErrors.email }" />
        </div>
        <div class="col-lg-12">
            <span v-if="formErrors.message" class="error-message">Poruka je obavezna</span>
          <textarea name="message" id="message" cols="30" rows="10" placeholder="Poruka" v-model="formData.message" :class="{ 'error': formErrors.message }"></textarea>
        </div>
        <div class="col-lg-12 col-md-6 col-12 text-center">
          <button class="main-btn">POŠALJI</button>
        </div>
      </div>
    </form>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      const formData = ref({
        name: '',
        email: '',
        message: '',
      });
  
      const formErrors = ref({
        name: false,
        email: false,
        message: false,
      });
  
      const submitForm = () => {
        // Validate form fields
        if (!formData.value.name) {
          formErrors.value.name = true;
        } else {
          formErrors.value.name = false;
        }
  
        if (!formData.value.email) {
          formErrors.value.email = true;
        } else {
          formErrors.value.email = false;
        }
  
        if (!formData.value.message) {
          formErrors.value.message = true;
        } else {
          formErrors.value.message = false;
        }
  
        // Submit form if no errors
        if (!formErrors.value.name && !formErrors.value.email && !formErrors.value.message) {
          // Your form submission logic here
          console.log('Form submitted successfully!');
        }
      };
  
      return {
        formData,
        formErrors,
        submitForm,
      };
    },
  };
  </script>
  
  <style>
  .error {
    border-color: red;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
  }

  h2, input {
  color: #1d2143 !important;
}

input::placeholder {
    color: #1d2143;
 }
  </style>
  