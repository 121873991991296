<template>
  <NavbarHome3 />
  <Breadcrumb mainTitle="O udruženju" subTitle="O udruženju" />
  <!--FAQ Section -->

  <div class="faq-section pt-50 pb-50">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="accordion faqs" id="accordionFaq">
            <div class="card" v-for="tab in tabs" :key="tab.id">
              <div class="card-header" id="heading1">
                <h5 class="mb-0 subtitle">
                  <button
                    class="btn btn-link collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse-' + tab.id"
                    aria-expanded="false"
                    aria-controls="collapse7"
                  >
                    {{ tab.name }}
                  </button>
                </h5>
              </div>

              <div
                :id="'collapse-' + tab.id"
                class="collapse"
                aria-labelledby="heading1"
                data-parent="#accordionFaq"
              >
                <div class="card-body">
                  <div class="content">
                    <AboutMembers v-if="tab.id === 1" />
                    <AboutTab2 v-if="tab.id === 2" />
                    <AboutTab3 v-if="tab.id === 3" />
                    <AboutTab4 v-if="tab.id === 4" />
                    <AboutTab5 v-if="tab.id === 5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";
import AboutTab2 from "@/views/AboutTab2.vue";
import AboutTab3 from "@/views/AboutTab3.vue";
import AboutTab4 from "@/views/AboutTab4.vue";
import AboutTab5 from "@/views/AboutTab5.vue";
import AboutMembers from "@/views/AboutMembers.vue";



import axios from "axios";

export default {
  name: "Faq",
  components: { NavbarHome3, Breadcrumb, Footer, AboutTab2, AboutTab3, AboutTab4, AboutTab5, AboutMembers},
  data() {
    return {
      tabs: [
        { id: 1, name: 'Članovi' },
        { id: 2, name: 'Organi' },
        { id: 3, name: 'Kodeks pravobranilačke etike' },
        { id: 4, name: 'Statut' },
        { id: 5, name: 'Pravila pred sudom časti' },
      ],
    };
  },

  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "O udruženju | UPUFBiH",
  description: "O udruženju | UPUFBiH",
  ogDescription: "O udruženju | UPUFBiH",
  ogTitle: "O udruženju | UPUFBiH",
});
</script>
