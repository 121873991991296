<template>
  <NavbarHome3 />
  <!-- Hero Area -->

  <div class="homepage-slides">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="30"
      navigation
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 4000 }"
    >
      <swiper-slide>
        <div class="single-slide-item hero-area-bg-5">
          <div class="overlay-2"></div>
          <div class="hero-area-content">
            <div class="container">
              <div class="row justify-content-center">
                <div
                  class="col-lg-8 col-md-10 col-11 text-center wow fadeInUp animated"
                  data-wow-delay=".2s"
                >
                  <div class="section-title">
                    <h6>UPUFBIH</h6>
                    <h1>Udruženje pravobranilaca u <b>Federaciji Bosne i Hercegovine</b></h1>
                    <p>
                      Zalaganje za dosljednu primjenu ustava i zakona te jačanje pravne države
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>

  <!-- Promo Area -->

  <div class="promo-area">
    <div class="container">
      <div class="row gx-0">
        <div class="col-lg-4 col-12">
          <div class="single-promo-item mb-50">
            <div class="promo-icon">
              <i class="flaticon-lawyer"></i>
            </div>
            <h5>Pravila pred Sudom Časti</h5>
            <p>
              Pravila u postupku pred Sudom Časti Udruženja pravobranilaca u Federaciji Bosne i Hercegovine
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="single-promo-item mb-50 spro">
            <div class="promo-icon">
              <i class="flaticon-courthouse"></i>
            </div>
            <h5>Kodeks pravobranilačke etike</h5>
            <p>
              Uvažavajući ulogu i značaj pravobranilačke funkcije u pogledu zaštite imovine i imovinskih imteresa Federacije Bosne i Hercegovine ...
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="single-promo-item mb-50">
            <div class="promo-icon">
              <i class="flaticon-podium"></i>
            </div>
            <h5>Statut</h5>
            <p>
              Statut Udruženja pravobranilaca u Federaciji Bosne i Hercegovine
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!-- Blog Section-->

    <div class="blog-area gray-bg section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="section-title">
            <h6>Novosti</h6>
            <h2>Posljednje <b>novosti</b></h2>
          </div>
        </div>

        <div class="col-lg-6 text-right"></div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
          data-wow-delay=".4s"
          v-for="event in events"
          :key="event.blogId"
        >
          <div class="single-blog-item">
            <div class="blog-bg">
              <img v-if="event.imageUrl" :src="event.imageUrl" alt="" />
              <img v-else src="assets/images/evenet-default.jpeg" alt="" style="max-height: 272px; width: 100%" />
            </div>
            <div class="blog-content">
              <p class="blog-meta">
                <i class="las la-user-circle"></i>Admin |
                <i class="las la-calendar-check"></i>{{ event.dateAdded.split('T')[0] }}
              </p>
              <h5>
                <router-link
                  :to="{ name: 'event', params: { id: event.blogId } }"
                  >{{ event.title }}</router-link
                >
              </h5>
              <div v-html="eventContent(event.content)"></div>
              <router-link
                :to="{ name: 'event', params: { id: event.blogId } }"
                class="read-more"
                >Pročitaj više</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-lg-12 text-center">
          <router-link to="/novosti" class="main-btn">Pogledaj sve novosti</router-link>
        </div>
      </div>
    </div>
  </div>

  <!-- About Us Area-->

  <div id="about-3" class="about-area bg-cover section-padding">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 col-md-12 wow fadeInLeft" data-wow-delay=".3s">
          <div class="about-content-area">
            <div class="section-title">
              <h6>O udruženju</h6>
              <h2>
                Upoznajte nas
                <!-- <b>Trusted Clients</b> -->
              </h2>
            </div>

            <p>
              Udruženje pravobranilaca/pravobranitelja U Federaciji Bosne i Hercegovine predstavlja asocijaciju koja je formirana na slobodnoj osnovi s ciljem eﬁkasnijeg ostvarivanja profesionalnih aktivnosti usmjerenih na jačanje digniteta pravobranilačke funkcije, te promovisanja i zaštite kako zajedničkih tako i pojedinačnih prava i interesa svakog pravobranioca člana udruženja.
            </p>

            <ul class="about-key mt-30">
              <li>
                <i class="las la-check-square"></i>
                <p>Statut udruženje</p>
              </li>
              <li>
                <i class="las la-check-square"></i>
                <p>Kodeks pravobranilačke etike</p>
              </li>
              <li>
                <i class="las la-check-square"></i>
                <p>Pravila pred sudom časti</p>
              </li>
              <li>
                <i class="las la-check-square"></i>
                <p>Članovi udruženja</p>
              </li>
            </ul>
            <div class="about-contact">
              <div class="row">
                <div class="col-lg-6">
                  <div class="call-us d-flex mt-30">
                    <div class="icon"><i class="las la-phone"></i></div>
                    <div class="mobile-number">
                      <h5>Više informacija o odruženju na</h5>
                      <p>+387 61 888 000</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <a href="/o-udruzenju" class="main-btn">Pročitaj više</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 wow fadeInRight" data-wow-delay=".4s">
          <div class="about-bg">
            <img src="assets/images/about3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Gallery Area -->

  <div id="case-3" class="case-area section-padding">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-title text-center">
            <h6>Galerija slika</h6>
            <h2>Pogledajte posljednje dodane <b>fotografije</b></h2>
          </div>
        </div>
      </div>
      <div class="case-slider">
        <!-- <div class="row"> -->
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :space-between="30"
          :autoplay="{ delay: 4000 }"
          :breakpoints="{
            '575': {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            '768': {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            '990': {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            '1024': {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }"
        >
          <swiper-slide v-for="photo in photos" :key="photo.galleryID">
            <div
              class="single-case-item mb-50 bg-cover"
              :style="
                'background-image:url(' + photo.url + ')'
              "
            >
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>

  <CTA />
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import CTA from "@/components/CTA.vue";
import Footer from "@/components/Footer.vue";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";


export default {
  name: "Home3",
  components: { NavbarHome3, Swiper, SwiperSlide, CTA, Footer },
  data() {
    return {
      modules: [Navigation, Pagination, A11y, Autoplay],
    };
  },

  async mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },

  computed: {
    events() {
      return this.$store.getters.events.slice(0, 3) || [];
    },
    photos() {
      return this.$store.getters.galleryPhotos.slice(0, 10);
    },
  },

  methods: {
    eventContent(content) {
      return content.substring(0, 100) + "...";
    },
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "UPUFBiH | Udruženje pravobranioca/pravobranilaca Federacije Bosne i Hercegovine",
  description: "Početna | UPUFBiH",
  ogDescription: "Početna | UPUFBiH",
  ogTitle: "Početna | UPUFBiH",
});
</script>
