<template>
    <NavbarHome3 />
    <Breadcrumb mainTitle="Projekti" subTitle="Projekti" />
  
    <div id="service-2" class="services-area section-padding pb-50">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft"
            data-wow-delay=".2s"
            v-for="(service, i) in projects"
            :key="service.blogId"
          >
            <div
              class="single-service-area mb-50"
              style="height: 400px;"
            >
              <div class="service-icon">
                <i class="flaticon-contract"></i>
              </div>
              <div v-html="service.content" />
              <p>{{ service.title }}</p>
              <a :href="service.fileUrl" target="_blank" class="main-btn small-btn">Preuzmi dokument</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </template>
  
  <script>
  // @ is an alias to /src
  import NavbarHome3 from "@/components/NavbarHome3.vue";
  import Breadcrumb from "@/components/Breadcrumb.vue";
  import CTA from "@/components/CTA.vue";
  import TestimonialTwo from "@/components/TestimonialTwo.vue";
  import Footer from "@/components/Footer.vue";
  
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Navigation, Pagination, A11y, Autoplay } from "swiper";
  
  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/autoplay";
  
  import axios from "axios";
  
  export default {
    name: "Service",
    components: {
      NavbarHome3,
      Breadcrumb,
      Swiper,
      SwiperSlide,
      CTA,
      TestimonialTwo,
      Footer,
    },
    data() {
      return {
        prices: [],
        services: [],
        images: [],
  
        modules: [Navigation, Pagination, A11y, Autoplay],
        swiperOptions: {
          breakpoints: {
            354: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            575: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 50,
            },
  
            990: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          },
        },
      };
    },
  
    mounted() {
      //scrolling to top of the window
      window.scrollTo(0, 0);
    },

    computed: {
    projects() {
      return this.$store.getters.projects;
    },
  },
  };
  </script>
  <script setup>
  import { useSeoMeta } from "@vueuse/head";
  
  useSeoMeta({
    title: "Projekti | UPUFBiH",
    description: "Projekti | UPUFBiH",
    ogDescription: "Projekti | UPUFBiH",
    ogTitle: "Projekti | UPUFBiH",
  });
  </script>
  