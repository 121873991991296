<template>
  <NavbarHome3 />
  <Breadcrumb mainTitle="Postani član" subTitle="Postani član" />

  <!-- Sign Up  -->

  <div class="main-wrapper gray-bg">
    <div class="image-holder">
      <img src="/assets/images/clanstvo.jpg" alt="" />
    </div>
    <div class="form-inner">
      <form action="">
        <div class="form-header">
          <h3>Postani član udruženja</h3>
          <hr />
        </div>
        <div class="form-group">
          <label for="">Ime i prezime</label>
          <input type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">Naziv pravobranilaštva</label>
          <input type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">Pozicija u pravobranilaštvu</label>
          <input type="text" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">E-mail</label>
          <input type="email" class="form-control" />
        </div>
        <div class="form-group">
          <label for="">Kontakt telefon</label>
          <input type="text" class="form-control" />
        </div>

        <div class="form-group">
          <a href="#" class="main-btn">Pošalji prijavu</a>
        </div>
      </form>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import NavbarHome3 from "@/components/NavbarHome3.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Registration",
  components: { NavbarHome3, Breadcrumb, Footer },
  data() {
    return {};
  },
  mounted() {
    //scrolling to top of the window
    window.scrollTo(0, 0);
  },
};
</script>
<script setup>
import { useSeoMeta } from "@vueuse/head";
useSeoMeta({
  title: "Registration | Pitron | Lawyers & Law Firm Vue Js Template",
  description: "Registration page | Lawyers & Law Firm Vue Js Template",
  ogDescription: "Registration page | Lawyers & Law Firm Vue Js Template",
  ogTitle: "Registration | Pitron | Lawyers & Law Firm Vue Js Template",
});
</script>
